import axios from 'axios'
import AuthToken from '@/auth/auth-token'
import { paginate } from './paginate'

class AttributeAPI {
  constructor () {
    const base = axios.create({
      baseURL: process.env.VUE_APP_BASE_API,
      withCredentials: false,
      headers: {
        'Content-type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
      }
    })

    this.api = base
  }
}

class Attribute extends AttributeAPI {
  async post (data) {
    await AuthToken.setAuth(this.api)

    return this.api({
      url: 'v1/party_attribute',
      method: 'POST',
      data: data
    })
  }

  async get (id) {
    await AuthToken.setAuth(this.api)

    return this.api({
      url: `v1/party_attribute/${id}`,
      method: 'GET'
    })
  }

  async getList (partyTypeId, limit, offset, paging = true) {
    await AuthToken.setAuth(this.api)

    if (paging) {
      const url = `v1/party_attribute/${partyTypeId}`
      return paginate(this.api, url, limit, offset)
    }
    return this.api({
      url: `v1/party_attribute/${partyTypeId}/${limit}/${offset}`,
      method: 'GET'
    })
  }

  async put (id, data) {
    await AuthToken.setAuth(this.api)

    return this.api({
      url: `v1/party_attribute/${id}`,
      method: 'PUT',
      data: data
    })
  }
}

class AttributeValue extends AttributeAPI {
  async post (data) {
    return this.api({
      url: 'v1/party_attribute_value',
      method: 'POST',
      data: data
    })
  }

  async get (id) {
    await AuthToken.setAuth(this.api)

    return this.api({
      url: `v1/party_attribute_value/${id}`,
      method: 'GET'
    })
  }

  async getList (id, limit, offset, paging = true) {
    await AuthToken.setAuth(this.api)

    if (paging) {
      const url = `v1/party_attribute_value/${id}`
      return paginate(this.api, url, limit, offset)
    }
    return this.api({
      url: `v1/party_attribute_value/${id}/${limit}/${offset}`,
      method: 'GET'
    })
  }

  async put (id, data) {
    await AuthToken.setAuth(this.api)

    return this.api({
      url: `v1/party_attribute_value/${id}`,
      method: 'PUT',
      data: data
    })
  }

  async delete (id, data) {
    return this.api({
      url: `v1/party_attribute_value/${id}`,
      method: 'DELETE',
      data: data
    })
  }
}

export default {
  attribute: new Attribute(),
  attributeValue: new AttributeValue()
}
